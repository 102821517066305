.directory-container {
  display: flex;
  width: 80%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 5px !important;
  margin: 10px 0;
}

@media (max-width: 1200px) {
  .directory-container {
    width: 100%;
    margin: 10px;
  }
}
