@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  position: relative;
  // font-family: "Roboto", sans-serif;
  
  
  body {
    font-family: "Roboto", sans-serif;
    width: 100%;
    background-color: #f3f4f6;
    font-weight:  400; 
  }

}
