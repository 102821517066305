.items-container {
  height: 250;
  flex: 0 0 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 10px;
  background-color: #fff;

  img {
    height: 100%;
    width: 360px;
  }

  .item-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px 5px;
    span {
      font-size: 1rem;
    }

    button {
      border: 1px solid black;
      font-size: 1rem;
    }
  }
}

@media (max-width: 1024px) {
  .items-container {
    img {
      width: 300px;
    }
  }
}
