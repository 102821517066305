.swiper-container {
  height: 400px;
  width: 100%;

  .swiper-slide-active {
    width: 100%;
  }

  .swiper-slide {
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;

    .review-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 0px 50px;
      text-align: center;

      .review {
        font-size: 1rem;
      }

      img {
        height: 130px;
        width: 130px;
        border-radius: 50%;
        margin: 10px 0;
      }

      .person-name {
        font-weight: bold;
        font-size: 1.1rem;
      }
    }
  }
}

@media (max-width: 1200px) {
  .swiper-container {
    .swiper-slide-active {
      width: 100% !important;
      margin: 0;
    }

    .swiper-slide {
      margin: 0;
      width: 100% !important;
    }
  }
}

@media (max-width: 415px) {
  .swiper-container {
    .swiper-slide {
      .review-container {
        padding: 0 0;
      }
    }
  }
}
