.reviews-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 150px 50px;

  .review-container {
    width: 80%;
    background-color: #fff;
    .review {
      text-align: center;
      padding: 20px 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .name-person {
        font-size: 1.5rem;
        font-weight: 600;
      }

      .review-text {
        line-height: 1.6rem;
        font-size: 1.1rem;
      }

      img {
        border-radius: 50%;
        margin: 10px;
        width: 200px;
        height: 200px;
      }

      span {
        color: #474f57;
      }
    }
  }
}

@media (max-width: 415px) {
  .reviews-page-container {
    margin: 150px 10px;

    .review-container {
      width: 100%;
      .review {
        padding: 20px 15px;
        .review-text {
          font-size: 1rem;
        }
      }
    }
  }
}
