.side-menu {
  height: 100vh;
  background-color: #f3f4f6;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 40%;
  max-width: 400px;
  z-index: 0;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
  overflow-y: auto;

  &.open {
    transform: translateX(0%);
  }

  .options-container-side-menu {
    height: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    .option-link-side-menu {
      font-size: 1.5rem;
      text-decoration: none;
      margin: 5px;
      color: black;
      padding: 5px 5px;
      border: 2px solid transparent;

      &:hover {
        border: 2px solid #fa7921 !important;
        text-decoration: underline;
        color: #fa7921 !important;
        transition: all 0.35s ease;
      }
    }
  }
}

@media (max-width: 415px) {
  .side-menu {
    width: 100%;
    .options-container-side-menu {
      height: 60%;

      .option-link-side-menu {
        font-size: 1.35rem;
      }
    }
  }
}

@media (max-height: 700px) {
  .side-menu {
    .options-container-side-menu {
      height: 80%;
    }
  }
}
