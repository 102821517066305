.about-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 50px;
  height: 65vh;

  .about-page {
    width: 80%;
    background-color: #fff;
    padding: 50px 50px;
  }
}

@media (max-width: 1300px) {
  .about-page-container {
    height: 90vh;
    .about-page {
      width: 95%;
    }
  }
}

@media (max-width: 1024px) {
  .about-page-container {
    height: 80vh;
    .about-page {
      width: 95%;
    }
  }
}

@media (max-width: 415px) {
  .about-page-container {
    height: 115vh;
    margin: 200px 10px;
    .about-page {
      width: 95%;
    }
  }
}

@media (max-width: 365px) {
  .about-page-container {
    height: 125vh;
    margin: 200px 10px;
    .about-page {
      width: 95%;
    }
  }
}

@media (max-width: 325px) {
  .about-page-container {
    height: 200vh;
    margin: 200px 10px;
    .about-page {
      width: 95%;
    }
  }
}
