.side-menu-button {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  height: 24px;
  width: 30px;
  background: transparent;
  border: none;
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
  z-index: 300;
  margin-right: 15px;

  .side-menu-button:focus {
    outline: none;
  }

  .side-button-line {
    width: 25px;
    height: 2px;
    background: black;
  }
}

.close-side-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  height: 24px;
  background: transparent;
  border: none;
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
  z-index: 300;
  margin-right: 15px;

  .close-side-button-line {
    position: absolute;
    width: 25px;
    height: 2px;
    background: black;
    transform: rotate(-45deg);
    transition: transform 0.35s;
  }

  .close-side-button-line-2 {
    width: 25px;
    height: 2px;
    background: black;
    transform: rotate(45deg);
    transition: transform 0.35s;
  }

  &.non-active {
    .close-side-button-line {
      transform: rotate(0deg);
      transition: transform 0.35s;
    }

    .close-side-button-line-2 {
      transform: rotate(0deg);
      transition: transform 0.35s;
    }
  }
}

@media (min-width: 769px) {
  .side-menu-button {
    display: none;
  }
}
