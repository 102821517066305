@mixin anotherStyleHeader {
  position: fixed;
  top: 0;
  color: black !important;
  background-color: #f3f4f6;
  background-size: 100%;
  transition: all 0.35s ease-in;

  .options-container {
    a {
      color: black !important;
    }
  }

  svg {
    fill: black;

    &:hover {
      fill: #fa7921;
    }
  }
}

.header-container {
  margin: auto;
  padding: 0px 12%;
  left: 0;
  right: 0;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  color: #000;
  z-index: 100;
  transition: all 0.35s ease-in;

  svg {
    fill: #000;
  }

  &.scroll {
    position: fixed;
    @include anotherStyleHeader();
  }

  &.another-style {
    @include anotherStyleHeader();
  }

  //background-color: lightgray;

  .logo-container {
    height: 100%;
    width: 300px;
    display: flex;
    align-items: center;

    a {
      text-decoration: none !important;
      color: #fa7921;
      -webkit-text-stroke: 0.35px black;
      font-size: 2.25rem;
      z-index: 1;
      margin: 0;
      font-family: "Bad Script", cursive !important;
      font-weight: 400;
      // font-style:italic;
    }
  }

  .options-container {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .option-link {
      color: #000;
      padding: 10px 15px;
      cursor: pointer;
      border: 2px solid transparent;
      text-decoration: none !important;

      &:hover {
        border: 2px solid #fa7921 !important;
        text-decoration: underline;
        color: #fa7921 !important;
        transition: all 0.35s ease;
      }
    }
  }
}

@media (max-width: 1024px) {
  .header-container {
    padding: 0 8%;
    .logo-container {
      a {
        font-size: 2rem;
      }
    }

    .options-container {
      width: 90%;
    }
  }
}

@media (max-width: 769px) {
  .header-container {
    padding: 0 8%;

    .options-container {
      width: 90%;
      .option-link {
        display: none;
      }
    }
  }
}

@media (max-width: 415px) {
  .header-container {
    .logo-container {
      width: 100%;
      a {
      }
    }

    .options-container {
      width: 50%;
    }
  }
}
