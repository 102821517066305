.item-page-container {
  display: flex;
  width: 100%;
  flex-direction: row !important;
  justify-content: center !important;
  flex-wrap: wrap !important;

  .name-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin: 20px 20px;

    img {
      height: 225px;
      width: 360px;
    }
  }

  .description-container {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .description {
      margin: 10px 0;
    }

    select {
      margin: 0 10px 0 0;
      font-size: 1.1rem;
    }

    button {
      font-size: 1.2rem;
    }
  }
}

@media (max-width: 415px) {
  .item-page-container {
    flex-direction: column;
    .description-container {
      width: 75%;
    }
  }
}
