$font-color: #454d55;

.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  padding: 10px 10px;
  text-align: center;
  background-color: #f3f4f6;

  h3 {
    color: $font-color;
    font-size: 2rem;
    padding-bottom: 5px;
    margin-bottom: 5px;
  }

  img {
    padding: 10px 0px;
  }

  p {
    color: $font-color;
    font-size: 1.1rem;
    margin-bottom: 10px;
  }
}

@media (max-width: 1600px) {
  .about-container {
    width: 80%;
    p {
      font-size: 1rem;
    }
  }
}

@media (max-width: 800px) {
  .about-container {
    width: 90%;
    p {
      font-size: 1rem;
    }
  }
}

@media (max-width: 415px) {
  .about-container {
    width: 100%;
    p {
      font-size: 1rem;
    }
  }
}
