.footer-container {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .footer-items-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 80%;

    .footer-item {
      display: flex;
      flex-direction: column;
      margin: 20px 20px;
      height: 140px;
      width: 300px;

      .icon {
        text-align: center;

        margin: 1px 10px 0 20px;
        font-size: 2.5rem;
        color: #db6e24;
        transition: all 0.35s ease-in-out;
        text-decoration: none !important;
        &:hover {
          cursor: pointer;
          color: #7a3e15;
          transition: all 0.15s ease-in-out;
        }
      }

      .footer-name {
        color: #676e74;
        font-weight: 500;
        font-size: 1.2rem;
      }

      .underline {
        background: #dfe0e1;
        position: relative;
        height: 1px;

        &::before {
          content: "";
          background: #db6e24;
          width: 30%;
          height: 1px;
          position: absolute;
        }
      }

      .footer-text {
        margin: 10px 0;
        color: #676e74;
        font-size: 1.1rem;
      }
    }
  }

  .bottom-footer-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #323b43;
    flex-wrap: wrap;
    .bottom-footer {
      width: 80%;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;

      .bottom-footer-item {
        height: 50px;
        color: #d6d4cf;
        .icon {
          text-decoration: none;
          margin: 1px 5px 0 0;
          font-size: 1.2rem;
          color: #d6d4cf;
          transition: all 0.35s ease-in-out;

          &:hover {
            cursor: pointer;
            color: #db6e24;
            transition: all 0.15s ease-in-out;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .footer-container {
    .footer-items-container {
      width: 100%;
      .footer-item {
        height: auto;
      }
    }

    .bottom-footer-container {
      .bottom-footer {
        width: 100%;
      }
    }
  }
}

@media (max-width: 415px) {
  .bottom-footer-container {
    .bottom-footer {
      flex-direction: column;
      width: 100%;
      .bottom-footer-item {
        padding-top: 10px;
        text-align: center;
      }
    }
  }
}
