.contact-page-container {
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  background-color: #fff;

  .contact {
    display: flex;
    flex-direction: column;

    select {
      border: 1px solid black;
      margin: 5px 0 5px 0;
      padding: 5px;
    }

    textarea {
      border: 1px solid black;
      margin: 5px 0 10px 0;
    }
  }
}

@media (max-height: 1200px) {
  .contact-page-container {
    height: 90vh;
  }
}

@media (max-width: 415px) {
  .contact-page-container {
    width: 90%;
    .contact {
      width: 90%;
    }
  }
}

@media (max-height: 700px) {
  .contact-page-container {
    height: 110vh;
  }
}
