.react-slideshow-wrapper {
  margin-top: -100px;
  z-index: 1;

  .each-slide {
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-size: cover;
      box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.45);
      height: 70vh;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      color: white;
      font-size: 1.5rem;
      text-align: center;
      height: 20%;
      width: 50%;

      &.large-text {
        font-size: 4rem;
      }
    }
  }
}

@media (max-width: 1201px) {
  .react-slideshow-wrapper {
    .each-slide {
      div {
        height: 60vh;
      }

      span {
        padding: 0px;
        font-size: 1.3rem;
        width: 60%;
        height: 10%;

        &.large-text {
          font-size: 3rem;
        }
      }
    }
  }
}

@media (max-width: 1025px) {
  .react-slideshow-wrapper {
    height: 55vh;
    .each-slide {
      span {
        height: 10%;
        width: 70%;
      }
    }
  }
}

@media (max-width: 769px) {
  .react-slideshow-wrapper {
    height: 55vh;
    .each-slide {
      span {
        height: 10%;
        width: 90%;
      }
    }
  }
}

@media (max-width: 415px) {
  .react-slideshow-wrapper {
    height: 55vh;
    .each-slide {
      span {
        height: 20%;
        width: 90%;
        font-size: 1.2rem;

        &.large-text {
          font-size: 1.9rem;
          margin-bottom: 10px;
        }
      }
    }
  }
}
