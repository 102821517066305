.reviews-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;

  h3 {
    text-align: center;
    font-size: 2.25rem;
    font-weight: 400;
    margin-bottom: 5px;
    color: #454d55;
  }

  .icon-container {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: 50%;
      background-color: #fff;

      padding: 13px 13px;

      i {
        position: relative;
        font-size: 1.2rem;
        color: orange;
      }
    }

    .outer-line {
      width: 100%;
      position: relative;
      margin: 15px;
      border-bottom: 1px solid #000;
    }
  }

  .reviews-page-button {
    padding: 10px;
    font-size: 1.2rem;
    margin: 5px 0 15px 0;
    background-color: #fa7921;
    color: #fff;
  }
}

@media (max-width: 1200px) {
  .reviews-container {
    width: 90%;

    .icon-container {
      .outer-line {
        margin: 0 40px 0 40px;
      }
    }
  }
}

@media (max-width: 415px) {
  .reviews-container {
    width: 100%;
  }
}
