.shop-page-container {
  margin-top: 100px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  .shop-page-container-items {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width: 100%;
  }
}
